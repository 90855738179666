import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Icon, Box, Section } from "@quarkly/widgets";
import { MdLocationOn, MdPhoneIphone, MdEmail } from "react-icons/md";
const defaultProps = {
	"padding": "60px 0 220px",
	"sm-padding": "40px 0",
	"color": "--dark",
	"background": "--color-green"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"margin": "0 0 24px 0",
			"color": "--light",
			"text-align": "center",
			"children": "Зв'яжіться з нами:"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "row",
			"justify-content": "flex-start",
			"align-items": "center",
			"color": "--light"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocationOn,
			"size": "35px",
			"display": "inline-block",
			"width": "35px",
			"height": "35px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"display": "inline-block",
			"children": "Адреса"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "12px 0",
			"color": "--light",
			"children": "вул. Набережна Перемоги 51, м. Дніпро, 49000"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "row",
			"justify-content": "flex-start",
			"align-items": "center",
			"color": "--light"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdPhoneIphone,
			"size": "35px",
			"display": "inline-block",
			"width": "35px",
			"height": "35px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"display": "inline-block",
			"children": "Телефон"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "12px 0",
			"color": "--light",
			"children": "+38 058-454-4016"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "row",
			"justify-content": "flex-start",
			"align-items": "center",
			"color": "--light"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdEmail,
			"size": "35px",
			"display": "inline-block",
			"width": "35px",
			"height": "35px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"display": "inline-block",
			"children": "Email"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "12px 0",
			"color": "--light",
			"children": "info@dniprodiscovery.live"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Icon {...override("icon")} />
					<Text {...override("text1")} />
				</Box>
				<Text {...override("text2")} />
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Icon {...override("icon1")} />
					<Text {...override("text3")} />
				</Box>
				<Text {...override("text4")} />
			</Box>
			<Box {...override("box5")}>
				<Box {...override("box6")}>
					<Icon {...override("icon2")} />
					<Text {...override("text5")} />
				</Box>
				<Text {...override("text6")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;